import { Character, Ship } from '@/types';

// Helper functions for creating entities of type Character
const createCharacter = (id: string, name: string, role: string = '', isGalacticLegend: boolean = false,alignment: 'light' | 'dark' = 'light'): Character => ({
  id,
  name,
  role,
  isGalacticLegend: isGalacticLegend ?? false, // Default to false if not provided
  alignment
});

// Helper functions for creating entities of type Ship
const createShip = (id: string, name: string, isCapital: boolean = false, alignment: 'light' | 'dark' = 'light'): Ship => ({
  id,
  name,
  type: isCapital ? 'capital' : 'regular',
  isCapital: isCapital ?? false, // Default to false if not provided
  alignment
});

// Capital Ships
// id(imageMapping.ts) - name - isCapital (true/false) - callOrder (optional)
export const capital_ships = {
  chimera: createShip('chimaera', 'Chimera', true, 'dark'),
  endurance: createShip('venator', 'Endurance', true, 'light'),
  executor: createShip('executor', 'Executor', true, 'dark'),
  executrix: createShip('stardestroyer', 'Executrix', true, 'dark'),
  finalizer: createShip('finalizer', 'Finalizer', true, 'dark'),
  home_one: createShip('moncalamarilibertycruiser', 'Home One', true, 'light'),
  leviathan: createShip('leviathan', 'Leviathan', true, 'dark'),
  malevolence: createShip('malevolence', 'Malevolence', true, 'dark'),
  negotiator: createShip('negotiator', 'Negotiator', true, 'light'),
  profundity: createShip('profundity', 'Profundity', true, 'light'),
  raddus: createShip('raddus', 'Raddus', true, 'light'),
} as const;

// Regular Ships
// id(imageMapping.ts) - name  - callOrder (optional)
export const ships = {
  ahsoka_tanos_jedi_starfighter: createShip('jedi_fighter_ahsoka', 'Ahsoka Tanos Jedi Starfighter', false, 'light'),
  anakin_eta_2: createShip('jedi_fighter_anakin', 'Anakin Eta-2', false, 'light'),
  b28_extinction_bomber: createShip('b28extinctionclassbomber', 'B-28 Extinction-Class Bomber', false, 'dark'),
  btl_b_y_wing: createShip('ywing_btlb', 'BTL-B Y-wing', false, 'light'),
  biggs_darklighter_x_wing: createShip('xwing_red3', 'Biggs Darklighters X-wing', false, 'light'),
  bistan_u_wing: createShip('uwing', 'Bistan U-wing', false, 'light'),
  cassian_u_wing: createShip('uwing_hero', 'Cassians U-wing', false, 'light'),
  clone_sergeant_arc_170: createShip('arc170', 'Clone Sergeants ARC-170', false, 'light'),
  comeuppance: createShip('comeuppance', 'Comeuppance', false, 'dark'),
  ebon_hawk: createShip('ebonhawk', 'Ebon Hawk', false, 'light'),
  emperors_shuttle: createShip('imperialshuttle', 'Emperors Shuttle', false, 'dark'),
  first_order_sf_tie_fighter: createShip('fosf_tie_fighter', 'First Order SF TIE Fighter', false, 'dark'),
  first_order_tie_fighter: createShip('firstorder_tiefighter', 'First Order TIE Fighter', false, 'dark'),
  fury_class_interceptor: createShip('furyclassinterceptor', 'Fury-class Interceptor', false, 'dark'),
  gauntlet_starfighter: createShip('gauntlet', 'Guantlet Starfighter', false, 'dark'),
  geonosian_soldier_starfighter: createShip('geonosis_fighter_soldier', 'Geonasian Soldiers Starfighter', false, 'dark'),
  geonosian_spy_starfighter: createShip('geonosis_fighter_spy', 'Geonosian Spys Starfighter', false, 'dark'),
  ghost: createShip('ghost', 'Ghost', false, 'light'),
  han_millennium_falcon: createShip('mfalcon', 'Hans Millennium Falcon', false, 'light'),
  hounds_tooth: createShip('houndstooth', 'Hounds Tooth', false, 'dark'),
  hyena_bomber: createShip('hyenabomber', 'Hyena Bomber', false, 'dark'),
  ig_2000: createShip('ig2000', 'IG-200', false, 'dark'),
  imperial_tie_bomber: createShip('tiebomber', 'Imperial TIE Bomber', false, 'dark'),
  imperial_tie_fighter: createShip('tiefighter', 'Imperial TIE Fighter', false, 'dark'),
  jedi_consular_starfighter: createShip('jedi_fighter', 'Jedi Consulars Starfighter', false, 'light'),
  kylo_ren_command_shuttle: createShip('upsilon_shuttle_kylo', 'Kyle Rens Command Shuttle', false, 'dark'),
  lando_millennium_falcon: createShip('mil_fal_pristine', 'Landos Millennium Falcon', false, 'light'),
  mg_100_starfortress: createShip('mg100', 'MG-100 StarFortress SF-17', false, 'light'),
  marauder: createShip('marauder', 'Marauder', false, 'dark'),
  mark_v1_interceptor: createShip('sithsupremacyclass', 'Mark V1 Interceptor', false, 'dark'),
  outrider: createShip('outrider', 'Outrider', false, 'light'),
  phantom2: createShip('phantom2', 'Phantom 2', false, 'light'),
  plo_koon_starfighter: createShip('jedi_fighter_bladeofdorin', 'Plo Koons Jedi Starfighter', false, 'light'),
  poe_dameron_x_wing: createShip('xwing_blackone', 'Poe Damerons X-wing', false, 'light'),
  punishing_one: createShip('punishingone', 'Punishing One', false, 'dark'),
  raven_claw: createShip('ravensclaw', 'Raven Claw', false, 'light'),
  razor_crest: createShip('razorcrest', 'Razor Crest', false, 'light'),
  rebel_y_wing: createShip('ywing', 'Rebel Y-wing', false, 'light'),
  resistance_x_wing: createShip('xwing_resistance', 'Resistance X-wing', false, 'light'),
  rex_arc_170: createShip('arc170_02', 'Rexs ARC-170', false, 'light'),
  rey_millennium_falcon: createShip('mfalcon_ep7', 'Reys Millennium Falcon', false, 'light'),
  scimitar: createShip('sithinfiltrator', 'Scimitar', false, 'dark'),
  scythe: createShip('scythe', 'Scythe', false, 'dark'),
  sithfighter: createShip('sithfighter', 'Sith Fighter', false, 'dark'),
  slave_1: createShip('slave1', 'Slave 1', false, 'dark'),
  sun_fac_geonosian_starfighter: createShip('geonosis_fighter_sunfac', 'Sun Facs Geonosian Starfighter', false, 'dark'),
  tie_advanced_x1: createShip('tieadvanced', 'TIE Advanced x1', false, 'dark'),
  tie_dagger: createShip('tiedagger', 'TIE Dagger', false, 'dark'),
  tie_defender: createShip('tiedefender', 'TIE Defender', false, 'dark'),
  tie_echelon: createShip('firstordertieechelon', 'TIE Echelon', false, 'dark'),
  tie_reaper: createShip('tiereaper', 'TIE Reaper', false, 'dark'),
  tie_silencer: createShip('tie_silencer', 'TIE Silencer', false, 'dark'),
  tie_interceptor: createShip('tie_interceptor_prototype', 'TIE/IN Interceptor', false, 'dark'),
  umbaran_starfighter: createShip('umbaran_star_fighter', 'Umbaran Starfighter', false, 'light'),
  vulture_droid: createShip('vulturedroid', 'Vulture Droid', false, 'dark'),
  wedge_antilles_x_wing: createShip('xwing_red2', 'Wedge Antilles X-wing', false, 'light'),
  xanadu_blood: createShip('xanadublood', 'Xanadu Blood', false, 'dark')
} as const;

// Galactic Legends
// id(match against imageMapping.ts) - name - role - isGalacticLegend (true/false) - alignment (light/dark)
export const characters = {
  triple_zero: createCharacter('triplezero', '0-0-0', 'Support', false, 'dark'),
  fifty_rt: createCharacter('50rt', '50R-T', 'Tank', false, 'light'),
  arc_trooper: createCharacter('trooperclone_arc', 'ARC Trooper', 'Attacker', false, 'light'),
  aayla_secura: createCharacter('aaylasecura', 'Aayla Secura', 'Tank', false, 'light'),
  admiral_ackbar: createCharacter('ackbaradmiral', 'Admiral Ackbar', 'Support', false, 'light'),
  admiral_piett: createCharacter('piett', 'Admiral Piett', 'Support', false, 'dark'),
  admiral_raddus: createCharacter('admiralraddus', 'Admiral Raddus', 'Support', false, 'light'),
  admiral_trench: createCharacter('admiraltrench', 'Admiral Trench', 'Support', false, 'dark'),
  ahsoka_fulcrum: createCharacter('ahsokaadult', 'Ahsoka Fulcrum', 'Attacker', false, 'light'),
  ahsoka_snips: createCharacter('ahsoka', 'Ahsoka Snips', 'Attacker', false, 'light'),
  amilyn_holdo: createCharacter('holdo', 'Amilyn Holdo', 'Support', false, 'light'),
  asajj_ventress: createCharacter('ventress', 'Asajj Ventress', 'Attacker', false, 'light'),
  aurra_sing: createCharacter('aurrasing', 'Auura Sing', 'Attacker', false, 'dark'),
  b1_battle_droid: createCharacter('b1', 'B1 Battle Droid', 'Support', false, 'dark'),
  b2_super_battle_droid: createCharacter('b2', 'B2 Super Battle Droid', 'Tank', false, 'dark'),
  bb_8: createCharacter('bb8', 'BB-8', 'Support', false, 'light'),
  battle_droid_1: createCharacter('bt1', 'Battle Droid 1', 'Attacker', false, 'dark'),
  barriss_offee: createCharacter('barriss_light', 'Barriss Offee', 'Healer', false, 'light'),
  bastila_shan: createCharacter('bastilashan', 'Bastila Shan', 'Support', false, 'light'),
  bastila_fallen: createCharacter('bastilashan_dark', 'Bastila (Fallen)', 'Support', false, 'dark'),
  baylan_skoll: createCharacter('baylanskoll', 'Baylan Skoll', 'Attacker', false, 'dark'),
  baze_malbus: createCharacter('bazemalbus', 'Baze Malbus', 'Tank', false, 'light'),
  ben_solo: createCharacter('bensolo', 'Ben Solo', 'Attacker', false, 'light'),
  biggs: createCharacter('rebelpilot_biggs', 'Biggs', 'Support', false, 'light'),
  bistan: createCharacter('bistan', 'Bistan', 'Attacker', false, 'light'),
  bo_katan_mandalore: createCharacter('mandalorbokatan', 'Bo-Katan Mandalore', 'Attacker', false, 'light'),
  bo_katan_kryze: createCharacter('bokatan', 'Bo-Katan Kryze', 'Attacker', false, 'light'),
  boba_fett: createCharacter('bobafett', 'Boba Fett', 'Attacker', false, 'dark'),
  boba_fett_scion: createCharacter('bobafettold', 'Boba Fett (Scion)', 'Attacker', false, 'dark'),
  bodhi_rook: createCharacter('bodhi', 'Bodhi Rook', 'Support', false, 'light'),
  boss_nass: createCharacter('bossnass', 'Boss Nass', 'Tank', false, 'light'),
  bossk: createCharacter('bossk', 'Bossk', 'Attacker', false, 'dark'),
  boushh_leia: createCharacter('boushh', 'Boushh Leia', 'Attacker', false, 'light'),
  c_3po: createCharacter('c3po', 'C-3PO', 'Support', false, 'light'),
  cc_2224_cody: createCharacter('trooperclone_cody', 'CC-2224 Cody', 'Support', false, 'light'),
  ct_21_0408_echo: createCharacter('trooperclone_echo', 'CT-21-0408 Echo', 'Support', false, 'light'),
  ct_5555_fives: createCharacter('trooperclone_fives', 'CT-5555 Fives', 'Tank', false, 'light'),
  ct_7567_rex: createCharacter('trooperclone_rex', 'CT-7567 Rex', 'Support', false, 'light'),
  cad_bane: createCharacter('cadbane', 'Cad Bane', 'Attacker', false, 'dark'),
  cal_kestis: createCharacter('calkestis', 'Cal Kestis', 'Attacker', false, 'light'),
  canderous_ordo: createCharacter('canderous', 'Canderous Ordo', 'Attacker', false, 'dark'),
  captain_drogan: createCharacter('captaindrogan', 'Captain Drogan', 'Support', false, 'light'),
  captain_enoch: createCharacter('captainenoch', 'Captain Enoch', 'Support', false, 'dark'),
  captain_han_solo: createCharacter('hoth_han', 'Captain Han Solo', 'Support', false, 'light'),
  captain_phasma: createCharacter('phasma', 'Captain Phasma', 'Support', false, 'dark'),
  captain_rex: createCharacter('captainrex', 'Captain Rex', 'Support', false, 'light'),
  captain_tarpals: createCharacter('captaintarpals', 'Captain Tarpals', 'Attacker', false, 'light'),
  cara_dune: createCharacter('caradune', 'Cara Dune', 'Attacker', false, 'light'),
  carth_onasi: createCharacter('carth', 'Carth Onasi', 'Attacker', false, 'light'),
  cassian_andor: createCharacter('cassian', 'Cassian Andor', 'Support', false, 'light'),
  cera_junda: createCharacter('cerejunda', 'Cera Junda', 'Support', false, 'light'),
  chewbacca: createCharacter('chewbacca_ot', 'Chewbacca', 'Attacker', false, 'light'),
  chief_chirpa: createCharacter('ewok_chirpa', 'Chief Chirpa', 'Support', false, 'light'),
  chief_nebit: createCharacter('jawa_nebit', 'Chief Nebit', 'Tank', false, 'light'),
  chirrut_imwe: createCharacter('chirrut', 'Chirrut Imwe', 'Attacker', false, 'light'),
  chopper: createCharacter('chopper', 'Chopper', 'Support', false, 'light'),
  clone_sergeant: createCharacter('trooperclonegreen', 'Clone Sergeant', 'Attacker', false, 'light'),
  clone_chewbacca: createCharacter('chewbacca', 'Clone Chewbacca', 'Tank', false, 'light'),
  colonel_stark: createCharacter('colonel_stark', 'Colonel Stark', 'Support', false, 'light'),
  commander_ahsoka: createCharacter('commanderahsokatano', 'Commander Ahsoka', 'Attacker', false, 'light'),
  commander_luke: createCharacter('lukebespin', 'Commander Luke Skywalker', 'Attacker', false, 'light'),
  coruscant_police: createCharacter('coruscantpolice', 'Coruscant UP', 'Tank', false, 'light'),
  count_dooku: createCharacter('dooku', 'Count Dooku', 'Attacker', false, 'dark'),
  old_daka: createCharacter('daka', 'Old Daka', 'Healer', false, 'dark'),
  dark_trooper: createCharacter('darktrooper', 'Dark Trooper', 'Attacker', false, 'dark'),
  dark_trooper_moff: createCharacter('moffgideons3', 'Dark Trooper Moff', 'Attacker', false, 'dark'),
  darth_bane: createCharacter('darthbane', 'Darth Bane', 'Attacker', false, 'dark'),
  darth_malak: createCharacter('darthmalak', 'Darth Malak', 'Attacker', false, 'dark'),
  darth_malgus: createCharacter('darthmalgus', 'Darth Malgus', 'Tank', false, 'dark'),
  darth_maul: createCharacter('maul', 'Darth Maul', 'Attacker', false, 'dark'),
  darth_nihilus: createCharacter('nihilus', 'Darth Nihilus', 'Attacker', false, 'dark'),
  darth_revan: createCharacter('sithrevan', 'Darth Revan', 'Attacker', false, 'dark'),
  darth_sidious: createCharacter('sidious', 'Darth Sidious', 'Attacker', false, 'dark'),
  darth_sion: createCharacter('sion', 'Darth Sion', 'Tank', false, 'dark'),
  darth_talon: createCharacter('darthtalon', 'Darth Talon', 'Attacker', false, 'dark'),
  darth_traya: createCharacter('traya', 'Darth Traya', 'Support', false, 'dark'),
  darth_vader: createCharacter('vader', 'Darth Vader', 'Attacker', false, 'dark'),
  dash_rendar: createCharacter('dashrendar', 'Dash Rendar', 'Attacker', false, 'light'),
  dathcha: createCharacter('jawa_dathcha', 'Dathcha', 'Support', false, 'light'),
  death_trooper_peridea: createCharacter('deathtrooperperidea', 'Death Trooper (Peridea)', 'Attacker', false, 'dark'),
  death_trooper: createCharacter('trooperdeath', 'Death Trooper', 'Attacker', false, 'dark'),
  dengar: createCharacter('dengar', 'Dengar', 'Attacker', false, 'dark'),
  director_krennic: createCharacter('krennic', 'Director Krennic', 'Support', false, 'dark'),
  doctor_aphra: createCharacter('doctoraphra', 'Dr Aphra', 'Support', false, 'dark'),
  droideka: createCharacter('droideka', 'Droideka', 'Attacker', false, 'dark'),
  echo: createCharacter('bb_echo', 'Echo', 'Support', false, 'light'),
  eeth_koth: createCharacter('eethkoth', 'Eeth Koth', 'Support', false, 'light'),
  eighth_brother: createCharacter('eighthbrother', 'Eight Brother', 'Attacker', false, 'dark'),
  embo: createCharacter('embo', 'Embo', 'Attacker', false, 'dark'),
  emperor_palpatine: createCharacter('palpatineemperor', 'Emperor Palpatine', 'Attacker', false, 'dark'),
  enfy_nest: createCharacter('enfys', 'Enfy Nest', 'Attacker', false, 'light'),
  ewok_elder: createCharacter('ewok_chief', 'Ewok Elder', 'Healer', false, 'light'),
  ewok_scout: createCharacter('ewok_scout', 'Ewok Scout', 'Attacker', false, 'light'),
  ezra_bridger: createCharacter('ezra_s3', 'Ezra Bridger', 'Attacker', false, 'light'),
  ezra_exile: createCharacter('ezraexile', 'Ezra (Exile)', 'Attacker', false, 'light'),
  fennec_shand: createCharacter('fennec', 'Fennec Shand', 'Attacker', false, 'light'),
  fifth_brother: createCharacter('fifthbrother', 'Fifth Brother', 'Attacker', false, 'dark'),
  finn: createCharacter('finnjakku', 'Finn', 'Tank', false, 'light'),
  fo_executioner: createCharacter('firstorder_executioner', 'FO Executioner', 'Attacker', false, 'dark'),
  fo_officer: createCharacter('firstorderofficer', 'FO Officer', 'Support', false, 'dark'),
  fo_sf_tie_pilot: createCharacter('firstorder_pilot', 'FO SF Tie Pilot', 'Attacker', false, 'dark'),
  fo_stormtrooper: createCharacter('firstordertrooper', 'FO Stormtrooper', 'Tank', false, 'dark'),
  fo_tie_pilot: createCharacter('firstordertiepilot', 'FO Tie Pilot', 'Attacker', false, 'dark'),
  gamorrean_guard: createCharacter('gamorreanguard', 'Gamorrean Guard', 'Tank', false, 'dark'),
  gar_saxon: createCharacter('gar_saxon', 'Gar Saxon', 'Attacker', false, 'dark'),
  garazeb_zeb: createCharacter('zeb_s3', 'Garazeb (Zeb)', 'Tank', false, 'light'),
  general_grievous: createCharacter('grievous', 'General Grievous', 'Attacker', false, 'dark'),
  general_hux: createCharacter('generalhux', 'General Hux', 'Support', false, 'dark'),
  general_kenobi: createCharacter('obiwangeneral', 'General Kenobi', 'Tank', false, 'light'),
  general_skywalker: createCharacter('generalanakin', 'General Skywalker', 'Attacker', false, 'light'),
  general_veers: createCharacter('veers', 'General Veers', 'Support', false, 'dark'),
  geonosian_brood_alpha: createCharacter('geonosian_broodalpha', 'Geonosian Brood Alpha', 'Support', false, 'dark'),
  geonosian_soldier: createCharacter('geonosian_soldier', 'Geonosian Soldier', 'Attacker', false, 'dark'),
  geonosian_spy: createCharacter('geonosian_spy', 'Geonosian Spy', 'Attacker', false, 'dark'),
  grand_admiral_thrawn: createCharacter('thrawn', 'Grand Admiral Thrawn', 'Support', false, 'dark'),
  grand_inquisitor: createCharacter('grandinquisitor', 'Grand Inquisitor', 'Attacker', false, 'dark'),
  grand_master_yoda: createCharacter('yodagrandmaster', 'Grand Master Yoda', 'Support', false, 'light'),
  grand_moff_tarkin: createCharacter('tarkinadmiral', 'Grand Moff Tarkin', 'Support', false, 'dark'),
  great_mothers: createCharacter('greatmothers', 'Great Mothers', 'Support', false, 'dark'),
  greef_karga: createCharacter('greefkarga', 'Greef Karga', 'Support', false, 'light'),
  greedo: createCharacter('greedo', 'Greedo', 'Attacker', false, 'light'),
  gungan_bombardier: createCharacter('gunganboomadier', 'Gungan Bombardier', 'Attacker', false, 'light'),
  gungan_phalanx: createCharacter('gunganphalanx', 'Gungan Phalanx', 'Tank', false, 'light'),
  hk_47: createCharacter('hk47', 'HK-47', 'Attacker', false, 'dark'),
  han_solo: createCharacter('han', 'Han Solo', 'Attacker', false, 'light'),
  hera_syndulla: createCharacter('hera_s3', 'Hera Syndulla', 'Support', false, 'light'),
  hermit_yoda: createCharacter('yodahermit', 'Hermit Yoda', 'Support', false, 'light'),
  hondo_ohnaka: createCharacter('hondoohnaka', 'Hondo Ohnaka', 'Support', false, 'light'),
  hoth_rebel_scout: createCharacter('rebelhothscout', 'Hoth Rebel Scout', 'Attacker', false, 'light'),
  hoth_rebel_soldier: createCharacter('rebelhoth', 'Hoth Rebel Soldier', 'Tank', false, 'light'),
  hunter: createCharacter('bb_hunter', 'Hunter', 'Attacker', false, 'light'),
  ig_100_magnaguard: createCharacter('magnaguard', 'IG-100 MagnaGuard', 'Tank', false, 'dark'),
  ig_11: createCharacter('ig11_nurse', 'IG-11', 'Tank', false, 'light'),
  ig_12_grogu: createCharacter('ig12', 'IG-12 & Grogu', 'Tank', false, 'light'),
  ig_86: createCharacter('ig86', 'IG-86', 'Attacker', false, 'dark'),
  ig_88: createCharacter('ig88', 'IG-88', 'Attacker', false, 'dark'),
  iden_versio: createCharacter('idenversioempire', 'Iden Versio', 'Attacker', false, 'dark'),
  ima_gun_di: createCharacter('imagundi', 'Ima-Gun Di', 'Support', false, 'light'),
  imperial_probe_droid: createCharacter('probedroid', 'Imperial Probe Droid', 'Support', false, 'dark'),
  imperial_super_commando: createCharacter('imperial_super_commando', 'Imperial Super Commando', 'Attacker', false, 'dark'),
  jabba_the_hutt: createCharacter('jabbathehutt', 'Jabba the Hutt', 'Support', true, 'dark'),
  jango_fett: createCharacter('jangofett', 'Jango Fett', 'Attacker', false, 'dark'),
  jar_jar_binks: createCharacter('jarjarbinks', 'Jar Jar Binks', 'Support', false, 'light'),
  jawa: createCharacter('jawa_jawa', 'Jawa', 'Attacker', false, 'light'),
  jawa_engineer: createCharacter('jawa_engineer', 'Jawa Engineer', 'Healer', false, 'light'),
  jawa_scavenger: createCharacter('jawa_scavenger', 'Jawa Scavenger', 'Support', false, 'light'),
  jedi_consular: createCharacter('jedi_consular_03', 'Jedi Consular', 'Healer', false, 'light'),
  jedi_knight_guardian: createCharacter('jedi_guardian_01', 'Jedi Knight Guardian', 'Tank', false, 'light'),
  jedi_knight_cal_kestis: createCharacter('jediknightcal', 'Jedi Knight Cal Kestis', 'Tank', false, 'light'),
  jedi_knight_anakin: createCharacter('anakinknight', 'Jedi Knight Anakin', 'Attacker', false, 'light'),
  jedi_knight_luke: createCharacter('luke_jediknight', 'Jedi Knight Luke Skywalker', 'Attacker', false, 'light'),
  jedi_knight_revan: createCharacter('jedirevan', 'Jedi Knight Revan', 'Attacker', false, 'light'),
  jedi_master_kenobi: createCharacter('globiwan', 'Jedi Master Kenobi', 'Tank', true, 'light'),
  jedi_master_luke: createCharacter('luke_jml', 'Jedi Master Luke Skywalker', 'Tank', true, 'light'),
  jolee_bindo: createCharacter('joleebindo', 'Jolee Bindo', 'Healer', false, 'light'),
  juhani: createCharacter('juhani', 'Juhani', 'Tank', false, 'light'),
  jyn_erso: createCharacter('jyn', 'Jyn Erso', 'Attacker', false, 'light'),
  k_2so: createCharacter('k2so', 'K-2SO', 'Tank', false, 'light'),
  kanan_jarrus: createCharacter('kanan_s3', 'Kanan Jarrus', 'Tank', false, 'light'),
  kelleran_beq: createCharacter('kelleranbeq', 'Kelleran Beq', 'Support', false, 'light'),
  ki_adi_mundi: createCharacter('kiadimundi', 'Ki-Adi-Mundi', 'Tank', false, 'light'),
  kit_fisto: createCharacter('kitfisto', 'Kit Fisto', 'Attacker', false, 'light'),
  krrsantan: createCharacter('krrsantan', 'Krrsantan', 'Tank', false, 'dark'),
  kuiil: createCharacter('kuiil', 'Kuiil', 'Support', false, 'light'),
  kyle_katarn: createCharacter('kylekatarn', 'Kyle Katarn', 'Attacker', false, 'light'),
  kylo_ren: createCharacter('kyloren', 'Kylo Ren', 'Attacker', false, 'dark'),
  kylo_ren_unmasked: createCharacter('kylo_unmasked', 'Kylo Ren (Unmasked)', 'Tank', false, 'dark'),
  l3_37: createCharacter('l337', 'L3-37', 'Tank', false, 'light'),
  lando_calrissian: createCharacter('landobespin', 'Lando Calrissian', 'Attacker', false, 'light'),
  leia_organa: createCharacter('leiaendor', 'Leia Organa', 'Attacker', true, 'light'),
  lobot: createCharacter('lobot', 'Lobot', 'Support', false, 'light'),
  logray: createCharacter('ewok_logray', 'Logray', 'Support', false, 'light'),
  lord_vader: createCharacter('lordvader', 'Lord Vader', 'Attacker', true, 'dark'),
  luke_farmboy: createCharacter('luke_ep4', 'Luke (Farmboy)', 'Attacker', false, 'light'),
  luminara_unduli: createCharacter('luminara', 'Luminara Unduli', 'Healer', false, 'light'),
  luthen_rael: createCharacter('luthenrael', 'Luthen Rael', 'Support', false, 'light'),
  mace_windu: createCharacter('macewindu', 'Mace Windu', 'Tank', false, 'light'),
  magmatrooper: createCharacter('trooperstorm_magma', 'Magmatrooper', 'Tank', false, 'dark'),
  mara_jade: createCharacter('marajade', 'Mara Jade', 'Attacker', false, 'dark'),
  marrok: createCharacter('marrok', 'Marrok', 'Attacker', false, 'dark'),
  master_qui_gon: createCharacter('masterquigon', 'Master Qui-Gon', 'Tank', false, 'light'),
  maul: createCharacter('maul_cyborg', 'Maul', 'Attacker', false, 'dark'),
  merrin: createCharacter('merrin', 'Merrin', 'Support', false, 'dark'),
  mission_vao: createCharacter('mission', 'Mission Vao', 'Attacker', false, 'light'),
  mob_enforcer: createCharacter('mob_enforcer', 'Mob Enforcer', 'Tank', false, 'dark'),
  moff_gideon: createCharacter('moffgideon', 'Moff Gideon', 'Support', false, 'dark'),
  mon_mothma: createCharacter('monmothma', 'Mon Mothma', 'Support', false, 'dark'),
  morgan_elsbeth: createCharacter('morganelsbeth', 'Morgan Elsbeth', 'Attacker', false, 'dark'),
  mother_talzin: createCharacter('nightsisters_talzin', 'Mother Talzin', 'Support', false, 'dark'),
  night_trooper: createCharacter('nighttrooper_a', 'Night Trooper', 'Attacker', false, 'dark'),
  nightsister_acolyte: createCharacter('nightsister_acolyte', 'Nightsister Acolyte', 'Attacker', false, 'dark'),
  nightsister_initiate: createCharacter('nightsister_initiate', 'Nightsister Initiate', 'Attacker', false, 'dark'),
  nightsister_spirit: createCharacter('nightsisters_wraith', 'Nightsister Spirit', 'Attacker', false, 'dark'),
  nightsister_zombie: createCharacter('nightsisters_zombie', 'Nightsister Zombie', 'Tank', false, 'dark'),
  ninth_sister: createCharacter('ninthsister', 'Ninth Sister', 'Tank', false, 'dark'),
  nute_gunray: createCharacter('nutegunray', 'Nute Gunray', 'Support', false, 'dark'),
  obi_wan_old_ben: createCharacter('obiwanep4', 'Obi-Wan (Old Ben)', 'Tank', false, 'light'),
  omega: createCharacter('badbatchomega', 'Omega', 'Support', false, 'light'),
  padawan_obiwan: createCharacter('padawanobiwan', 'Padawan Obi-Wan', 'Support', false, 'light'),
  padawan_sabine: createCharacter('padawansabine', 'Padawan Sabine Wren', 'Attacker', false, 'light'),
  padme_amidala: createCharacter('padme_geonosis', 'Padmé Amidala', 'Support', false, 'light'),
  pao: createCharacter('pao', 'Pao', 'Attacker', false, 'light'),
  paploo: createCharacter('ewok_paploo', 'Paploo', 'Tank', false, 'light'),
  paz_vizsla: createCharacter('pazvizsla', 'Paz Vizsla', 'Tank', false, 'light'),
  plo_koon: createCharacter('plokoon', 'Plo Koon', 'Tank', false, 'light'),
  poe_dameron: createCharacter('poe', 'Poe Dameron', 'Attacker', false, 'light'),
  poggle_the_lesser: createCharacter('geonosian_poggle', 'Poggle The Lesser', 'Support', false, 'dark'),
  princess_kneesaa: createCharacter('princesskneesaa', 'Princess Kneesaa', 'Support', false, 'light'),
  princess_leia: createCharacter('leia_princess', 'Princess Leia', 'Attacker', false, 'light'),
  qira: createCharacter('qira', 'Qira', 'Support', false, 'light'),
  queen_amidala: createCharacter('queenamidala', 'Queen Amidala', 'Support', false, 'light'),
  qui_gon_jinn: createCharacter('quigon', 'Qui-Gon Jinn', 'Support', false, 'light'),
  r2_d2: createCharacter('r2d2', 'R2-D2', 'Support', false, 'light'),
  range_trooper: createCharacter('trooperranger', 'Range Trooper', 'Tank', false, 'dark'),
  rebel_officer_leia: createCharacter('leiahoth', 'Rebel Officer Leia', 'Support', false, 'light'),
  resistance_hero_finn: createCharacter('finn', 'Resistance Hero Finn', 'Tank', false, 'light'),
  resistance_hero_poe: createCharacter('poe_tros', 'Resistance Hero Poe', 'Attacker', false, 'light'),
  resistance_pilot: createCharacter('resistancepilot', 'Resistance Pilot', 'Support', false, 'light'),
  resistance_trooper: createCharacter('resistancetrooper', 'Resistance Trooper', 'Attacker', false, 'light'),
  rey_gl: createCharacter('reyjakku', 'Rey (Galactic Legend)', 'Attacker', true, 'light'),
  rey_jedi_training: createCharacter('rey_tlj', 'Rey (Jedi Training)', 'Tank', false, 'light'),
  rey_scavenger: createCharacter('rey_tros', 'Rey (Scavenger)', 'Tank', false, 'light'),
  rose_tico: createCharacter('rose', 'Rose Tico', 'Support', false, 'light'),
  royal_guard: createCharacter('royalguard', 'Royal Guard', 'Tank', false, 'dark'),
  sabine_wren: createCharacter('sabine_s3', 'Sabine Wren', 'Attacker', false, 'light'),
  sana_starros: createCharacter('sanastarros', 'Sana Starros', 'Attacker', false, 'light'),
  savage_opress: createCharacter('savageopress', 'Savage Opress', 'Tank', false, 'dark'),
  saw_gerrera: createCharacter('sawgerrera', 'Saw Gerrera', 'Attacker', false, 'light'),
  scarif_rebel_pathfinder: createCharacter('rebel_scarif', 'Scarif Rebel Pathfinder', 'Tank', false, 'light'),
  scout_trooper: createCharacter('trooperscout', 'Scout Trooper', 'Attacker', false, 'dark'),
  second_sister: createCharacter('secondsister', 'Second Sister', 'Attacker', false, 'dark'),
  seventh_sister: createCharacter('seventhsister', 'Seventh Sister', 'Attacker', false, 'dark'),
  shaak_ti: createCharacter('shaakti', 'Shaak Ti', 'Support', false, 'light'),
  shin_hati: createCharacter('shinhati', 'Shin Hati', 'Attacker', false, 'dark'),
  shoretrooper: createCharacter('troopershore', 'Shoretrooper', 'Tank', false, 'dark'),
  sith_assassin: createCharacter('sithassassin', 'Sith Assassin', 'Attacker', false, 'dark'),
  sith_eternal_emperor: createCharacter('espalpatine_pre', 'Sith Eternal Emperor', 'Attacker', true, 'dark'),
  sith_empire_trooper: createCharacter('sithtrooper', 'Sith Empire Trooper', 'Tank', false, 'dark'),
  sith_marauder: createCharacter('sithmarauder', 'Sith Marauder', 'Attacker', false, 'dark'),
  sith_trooper: createCharacter('firstorder_sithtrooper', 'Sith Trooper', 'Attacker', false, 'dark'),
  skiff_guard_lando: createCharacter('undercoverlando', 'Skiff Guard (Lando)', 'Attacker', false, 'light'),
  snowtrooper: createCharacter('troopersnow', 'Snowtrooper', 'Attacker', false, 'dark'),
  starkiller: createCharacter('starkiller', 'Starkiller', 'Attacker', false, 'dark'),
  stap: createCharacter('stap', 'STAP', 'Attacker', false, 'dark'),
  stormtrooper: createCharacter('trooperstorm', 'Stormtrooper', 'Tank', false, 'dark'),
  stormtrooper_han: createCharacter('trooperstorm_han', 'Stormtrooper Han', 'Support', false, 'light'),
  sun_fac: createCharacter('geonosian_sunfac', 'Sun Fac', 'Tank', false, 'dark'),
  supreme_leader_kylo: createCharacter('kyloren_tros', 'Supreme Leader Kylo Ren', 'Attacker', true, 'dark'),
  t3_m4: createCharacter('t3m4', 'T3-M4', 'Support', false, 'light'),
  talia: createCharacter('talia', 'Talia', 'Healer', false, 'dark'),
  tarfful: createCharacter('tarfful', 'Tarfful', 'Tank', false, 'light'),
  taron_malicos: createCharacter('taronmalicos', 'Taron Malicos', 'Attacker', false, 'dark'),
  tech: createCharacter('bb_tech', 'Tech', 'Support', false, 'light'),
  teebo: createCharacter('ewok_teebo', 'Teebo', 'Tank', false, 'light'),
  the_armorer: createCharacter('armorer', 'The Armorer', 'Tank', false, 'light'),
  the_mandalorian: createCharacter('mandalorian', 'The Mandalorian', 'Attacker', false, 'light'),
  the_mandalorian_beskar: createCharacter('mandobeskar', 'The Mandalorian (Beskar)', 'Attacker', false, 'light'),
  third_sister: createCharacter('thirdsister', 'Third Sister', 'Attacker', false, 'dark'),
  threepio_and_chewie: createCharacter('chewbacca_c3po', 'Threepio & Chewie', 'Support', false, 'light'),
  tusken_chieftain: createCharacter('tuskenchieftain', 'Tusken Chieftain', 'Support', false, 'dark'),
  tusken_raider: createCharacter('tuskenraider', 'Tusken Raider', 'Attacker', false, 'dark'),
  tusken_shaman: createCharacter('tuskenshaman', 'Tusken Shaman', 'Healer', false, 'dark'),
  tusken_warrior: createCharacter('tuskenhuntress', 'Tusken Warrior', 'Attacker', false, 'dark'),
  urorrurrr: createCharacter('urorrurrr', 'UroRRuRRR', 'Support', false, 'light'),
  ugnaught: createCharacter('ugnaught', 'Ugnaught', 'Support', false, 'light'),
  vandor_chewbacca: createCharacter('chewbacca_vandor', 'Vandor Chewbacca', 'Tank', false, 'light'),
  veteran_smuggler_han: createCharacter('tfa_han', 'Veteran Smuggler Han Solo', 'Attacker', false, 'light'),
  visas_marr: createCharacter('visas', 'Visas Marr', 'Healer', false, 'light'),
  wampa: createCharacter('wampa', 'Wampa', 'Attacker', false, 'dark'),
  wat_tambor: createCharacter('wattambor', 'Wat Tambor', 'Support', false, 'dark'),
  wedge_antilles: createCharacter('rebelpilot_wedge', 'Wedge Antilles', 'Attacker', false, 'light'),
  wicket: createCharacter('ewok_wicket', 'Wicket', 'Attacker', false, 'light'),
  wrecker: createCharacter('bb_wrecker', 'Wrecker', 'Tank', false, 'light'),
  young_han_solo: createCharacter('han_young', 'Young Han Solo', 'Attacker', false, 'light'),
  young_lando: createCharacter('younglando', 'Young Lando', 'Scoundrel', false, 'light'),
  zaalbar: createCharacter('zaalbar', 'Zaalbar', 'Tank', false, 'light'),
  zam_wesell: createCharacter('zamwesell', 'Zam Wesell', 'Attacker', false, 'dark'),
  zorii_bliss: createCharacter('zoriibliss', 'Zorii Bliss', 'Attacker', false, 'light'),
} as const;

// Define base squads om vi vill ha fasta squads
// Ex:
/* 
  const clsRebels: Squad = {
  id: 'cls', // Ändra från 'lukebespin' till 'cls'
  name: 'CLS',
  type: 'squad', 
  alignment: 'light',
  leader: characters.commander_luke,
  characters: [
    characters.han_solo,
    characters.chewbacca,
    characters.c_3po,
    characters.threepio_and_chewie
  ]
};

// Define fleets
const executor: Fleet = {
  id: 'executor',
  name: 'Executor Fleet',
  type: 'fleet',
  alignment: 'dark',
  capitalShip: createShip('executor', 'Executor', true),
  startingLineup: [
    createShip('razor_crest', 'Razor Crest', false),
    createShip('hounds_tooth', "Hound's Tooth", false),
    createShip('xanadu_blood', 'Xanadu Blood', false)
  ],
  reinforcements: [
    createShip('slave_1', 'Slave I', false),
    createShip('ig2000', 'IG-2000', false),
    createShip('scimitar', 'Scimitar', false)
  ],
  callOrder: 'Call reinforcements in order: Slave I, IG-2000, Scimitar'
};

const profundity: Fleet = {
  id: 'profundity',
  name: 'Profundity Fleet',
  type: 'fleet',
  alignment: 'light',
  capitalShip: createShip('profundity', 'Profundity', true),
  startingLineup: [
    createShip('outrider', 'Outrider', false),
    createShip('ghost', 'Ghost', false),
    createShip('rebel_y_wing', 'Rebel Y-wing', false)
  ],
  reinforcements: [
    createShip('cassians_u_wing', "Cassian's U-wing", false),
    createShip('phantom', 'Phantom II', false),
    createShip('bistan_u_wing', "Bistan's U-wing", false)
  ],
  callOrder: 'Call reinforcements in order: Cassian U-wing, Phantom II, Bistan U-wing'
};


// Export initial collections
export const initialSquads: Squad[] = [clsRebels];
export const initialFleets: Fleet[] = [
  executor,
  profundity,
];

export const initialCounters: Counter[] = [
  {
    id: 'counter-1',
    targetSquad: executor,
    counterSquad: profundity,
    counterType: 'hard',
    description: "Profundity is a hard counter to Executor when used correctly",
    video_url: 'https://www.youtube.com/watch?v=example',
    strategy: [
      createStrategy(1, "Start with Outrider, Ghost, and Rebel Y-wing"),
      createStrategy(2, "Call in reinforcements in order: Cassian's U-wing, Phantom II")
    ],
    requirements: [
      createRequirement('mods', "Raddus: Speed set with high speed secondaries")
    ]
  },
];

*/